import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import {PageContentContainer} from "../styled/styled";
import {CenteredColumnFlexBox, CenteredRow, CenteredRowFlexBox} from "../simulation/create-simulation/styled";
import {useMemo, useRef, useState} from "react";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {createSmartWallet, getSmartWalletById, getSupportedChains, updateSmartWallet} from "../../api/hasura";
import useSWR from "swr";
import {useNavigate, useParams} from "react-router-dom";
import {useAppState} from "../../state/AppStateProvider";
import {SafeFactory, SafeAccountConfig} from '@safe-global/protocol-kit';
import Web3 from "web3";
import {LoadingButton} from "@mui/lab";
import WalletStatus from "./WalletStatus";
import IconButton from "@mui/material/IconButton";
import {Refresh} from "@mui/icons-material";


const steps = ['Create Wallet', 'Confirm Wallet', 'Wallet Details'];

export default function WalletCreationFlow() {

    const navigate = useNavigate();
    const {setSnackBar, provider, user} = useAppState();
    const {id} = useParams();

    const web3 = new Web3(provider as any)



    const {
        data: walletDetails,
        isLoading: isWalletLoading,
        isValidating: isWalletValidating,
        mutate: mutateWalletDetails
    } = useSWR(id ? `wallets/${id}` : null, () => getSmartWalletById(id));


    const {tx_hash} = walletDetails ?? {};


    console.log("walletDetails", walletDetails);
    console.log("wallet ID", id);


    // const [activeStep, setActiveStep] = React.useState(tx_hash ? 2 : id ? 1 : 0);

    const [isNextLoading, setIsNextLoading] = useState(false);

    const [selectedChain, setSelectedChain] = useState("11155111"); // Sepolia chain id

    const [walletName, setWalletName] = useState('');


    const prevActiveStepRef = useRef<number>(0);  // Allow number or null


    const activeStep = useMemo(() => {
        let result;
        if (isWalletLoading || isWalletValidating) {
            result = prevActiveStepRef.current; // Return previous value if loading or validating
        } else if (walletDetails?.tx_hash) {
            result = 2;
        } else if (id) {
            result = 1;
        } else {
            result = 0;
        }

        // Store the current result as the previous value for the next render
        prevActiveStepRef.current = result;
        return result ?? 0;
    }, [id, walletDetails, isWalletLoading, isWalletValidating]);


    const handleBack = () => {
        // if (activeStep > 0) {
        //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
        // }
    };


    const isNextDisabled = useMemo(() => {
        return activeStep === 0 && walletName.length < 3;
    }, [activeStep, walletName]);


    const getNextButtonLabel = () => {
        switch (activeStep) {
            case 0:
                return 'Create Wallet';
            case 1:
                return 'Confirm by Signing Transaction';
            case 2:
                return 'Finish';
            default:
                return 'Next';
        }
    };

    const getBackButtonLabel = () => {
        return activeStep === 0 ? 'Cancel' : 'Back';
    };

    const isBackButtonHidden = () => {
        return activeStep === 2;
    };

    const {
        data: supportedChains,
        isLoading: chainsLoading,
    } = useSWR("supported-chains", getSupportedChains)


    console.log("supportedChains", supportedChains);

    // const [supportedChains, setSupportedChains] = useState([
    //     {chain_id_hex: '0x1', chain_id: 1, name: 'Ethereum'},
    //     {chain_id_hex: '0x89', chain_id: 137, name: 'Polygon'},
    //     {chain_id_hex: '0xa', chain_id: 10, name: 'Optimism'},
    // ]);


    const onChainChange = (event: any) => {
        setSelectedChain(event.target.value as string);
    };


    const createSafeTransaction = async (eoaWalletAddress: string): Promise<{
        txHash?: string,
        address?: string,
        success: boolean
    }> => {
        if (!provider) {
            console.log('provider not initialized yet');
            return {success: false};
        }
        const address = user?.publicKey;
        const safeFactory = await SafeFactory.init({provider: provider});
        console.log('signed in with address: ', address);
        console.log('eoaWalletAddress: ', eoaWalletAddress);
        // console.log('secondWalletAddress: ', secondWalletAddress);

        if (!address || !eoaWalletAddress) {
            return {success: false};
        }
        const safeAccountConfig: SafeAccountConfig = {
            owners: [address, eoaWalletAddress],
            threshold: 2,
        };
        console.log("safeAccountConfig", safeAccountConfig);
        const nonce = await web3.eth.getTransactionCount(address);
        console.log('Nonce: ', nonce.toString());

        const saltNonce = nonce.toString();
        const predictedSafeAddress = await safeFactory.predictSafeAddress(safeAccountConfig, saltNonce);
        console.log('Predicted Safe Address: ', predictedSafeAddress);
        try {
            let txHash;
            const protocolKitOwner = await safeFactory.deploySafe({
                safeAccountConfig,
                saltNonce: saltNonce,
                callback: (callbackTxHash: string) => {
                    txHash = callbackTxHash;
                    console.log('callbackTxHash from deploy safe: ', callbackTxHash);
                },
            });
            const safeAddress = await protocolKitOwner.getAddress();

            console.log('Your Safe has been deployed:');
            console.log(`https://sepolia.etherscan.io/address/${safeAddress}`);
            console.log(`https://app.safe.global/sep:${safeAddress}`);

            if (!txHash) {
                console.error('Transaction hash not found');
                return {success: false};
            }
            return {address: safeAddress, txHash, success: true};


        } catch (error) {
            console.log('Error: ', error);
            return {success: false};
        }
    }


    const handleNext = async () => {


        if (activeStep === 0) {

            setIsNextLoading(true);

            const wallet = await createSmartWallet(
                selectedChain,
                walletName,
                'SAFE' // CURRENTLY ONLY AVAILABLE OPTION
            );

            setIsNextLoading(false);

            console.log("wallet", wallet);


            if (wallet.valid) {
                setSnackBar({
                    open: true,
                    message: "Wallet created. Please Confirm by Signing Transaction",
                    severity: "success"
                });
                // setActiveStep((prevActiveStep) => prevActiveStep + 1);
                navigate(`/wallets/create/${wallet.smartWalletDetails.id}`);
            } else {
                console.error("Error creating wallet", wallet);
                setSnackBar({open: true, message: `Wallet not created. ${wallet.message}`, severity: "error"});
            }

        } else if (activeStep === 1) {

            setIsNextLoading(true);

            const externalWalletAddress = walletDetails?.external_own_accounts?.[0]?.address;
            const transaction = await createSafeTransaction(externalWalletAddress);


            const {txHash, address} = transaction;

            if (txHash && address && transaction.success) {
                const updatedTrx = await updateSmartWallet(walletDetails?.id, txHash, address);

                setSnackBar({
                    open: true,
                    message: "Transaction Submitted and wallet created successfully.",
                    severity: "success"
                });

                mutateWalletDetails();
            }

            setIsNextLoading(false);
            return;

        } else if (activeStep === 2) {
            navigate(`/wallets/${id}`)

        }


    };


    const walletCreationForm = useMemo(() => {
        return (
            <Box sx={{width: "100%"}} component="form" noValidate autoComplete="off">
                <TextField
                    onChange={(e) => setWalletName(e.target.value)}
                    label="Wallet Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={walletName.length < 3}
                />

                {/*Error message if no wallet name*/}
                {walletName.length < 3 && (
                    <Box sx={{mb: 2, color: "#d32f2f", fontSize: "0.8rem", textAlign: "left"}}>
                        Wallet name must be at least 3 characters long
                    </Box>
                )}

                <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="wallet-type-label">Type</InputLabel>
                    <Select
                        labelId="wallet-type-label"
                        value="SAFE"
                        label="Type"
                        size="small"
                    >
                        <MenuItem value="SAFE">Safe</MenuItem>
                        <MenuItem disabled value="EULITH">Eulith</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="chain-label">Chain</InputLabel>
                    <Select
                        labelId="chain-label"
                        value={selectedChain}
                        disabled
                        onChange={onChainChange}
                        label="Chain"
                        size="small"
                    >
                        {supportedChains?.map(chain => (
                            <MenuItem value={chain.chain_id} key={chain.chain_id}>
                                {chain.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/*<TextField*/}
                {/*    label="Owner"*/}
                {/*    variant="outlined"*/}
                {/*    fullWidth*/}
                {/*    margin="normal"*/}
                {/*/>*/}
            </Box>
        );
    }, [selectedChain, supportedChains, walletName]);

// Example available chains array for reference
    const availableChains = [
        {chain_id: "11155111", name: "Sepolia Testnet"},
        {chain_id: "1", name: "Ethereum Mainnet"},
        // Add more chains as necessary
    ];

    const walletConfirmationView = useMemo(() => {
        // Find the chain name based on the chain_id
        const chainName = availableChains.find(chain => chain.chain_id === walletDetails?.chain_id)?.name || "Unknown Chain";

        // Predicted wallet address
        const predictedAddress = walletDetails?.external_own_accounts?.[0]?.address || "Address not available";

        return (
            <Box className={"w-100"}>
                <h2>Confirm Wallet Creation</h2>
                <div className={"gap-1 fc"}>
                    <div><strong>Name:</strong> {walletDetails?.name}</div>
                    <div><strong>Chain:</strong> {chainName} (ID: {walletDetails?.chain_id})</div>
                    <div><strong>Transaction hash:</strong> {walletDetails?.tx_hash ?? "N/A"}</div>
                    <div><strong>Type:</strong> {walletDetails?.type}</div>
                    <div><strong>Predicted Wallet Address:</strong> {predictedAddress}</div>
                    <div><strong>Is Layer 2:</strong> {walletDetails?.is_layer2 ? "Yes" : "No"}</div>
                    <div><strong>Is Testnet:</strong> {walletDetails?.is_testnet ? "Yes" : "No"}</div>
                </div>
            </Box>
        );
    }, [walletDetails]);


    const walletDepositAssetsView = useMemo(() => {
        return (
            <Box className={"w-100"}>
                <h2>Deposit Assets</h2>
                <div className={"gap-1 fc"}>
                    <div>Deposit Assets</div>
                </div>

                {/* Input field and button */}
                <TextField
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="number"
                    inputProps={{min: "0", step: "0.01"}}
                />
                <Button variant="contained" color="primary">
                    Deposit
                </Button>
            </Box>
        );
    }, []);


    const submittedWalletView = useMemo(() => {

        // Find the chain name based on the chain_id
        const chainName = availableChains?.find(chain => chain?.chain_id === walletDetails?.chain_id)?.name || "Unknown Chain";

        return (
            <Box className="w-100">

                <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>

                    <h2>
                        Wallet Details
                    </h2>

                    <Box>
                        <IconButton onClick={mutateWalletDetails} size="small">{isWalletLoading || isWalletValidating ?
                            <CircularProgress size={20}/> :
                            <Refresh/>}</IconButton>
                    </Box>

                </Box>

            {isWalletValidating ? <CenteredRow><CircularProgress /></CenteredRow> : <div className={"gap-1 fc"}>

                    <div><strong>Name:</strong> {walletDetails?.name}</div>
                    <div><strong>Status:</strong> <WalletStatus wallet={walletDetails}/></div>
                    <div><strong>Chain:</strong> {chainName} (ID: {walletDetails?.chain_id})</div>
                    <div><strong>Transaction hash:</strong> {walletDetails?.tx_hash ?? "N/A"}</div>
                    <div><strong>Type:</strong> {walletDetails?.type}</div>
                    <div><strong>Wallet Address:</strong> {walletDetails?.address}</div>
                    <div><strong>Is Layer 2:</strong> {walletDetails?.is_layer2 ? "Yes" : "No"}</div>
                    <div><strong>Is Testnet:</strong> {walletDetails?.is_testnet ? "Yes" : "No"}</div>


                </div>}
            </Box>
        );
    }, [id, walletDetails]);


    const getStepContent = (step: number) => {

        if (isWalletLoading) {
            return <Box sx={{my: 4}}><CircularProgress/></Box>;
        }
        switch (step) {
            case 0:
                return walletCreationForm;
            case 1:
                return walletConfirmationView;

            case 2:
                return submittedWalletView;

            default:
                return <div>Unknown Step</div>;
        }
    };

    return (
        <PageContentContainer sx={{my: 4, width: '100%'}}>
            <Stepper nonLinear={false} activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepButton color="inherit">
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <CenteredColumnFlexBox sx={{
                my: 4,
                mx: "auto",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                maxWidth: "640px"
            }}>
                {getStepContent(activeStep)}
            </CenteredColumnFlexBox>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                {/*{!isBackButtonHidden() && (*/}
                {/*    <Button onClick={handleBack} sx={{mr: 1}}>*/}
                {/*        {getBackButtonLabel()}*/}
                {/*    </Button>*/}
                {/*)}*/}
                <LoadingButton loading={isNextLoading} disabled={isNextDisabled} variant="contained"
                               onClick={handleNext}>
                    {getNextButtonLabel()}
                </LoadingButton>
            </Box>
        </PageContentContainer>
    );
}
