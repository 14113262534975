import React from "react";
import {LDKeyAgents} from "../../components/layout/Sidebar";
import {useAppState} from "../../state/AppStateProvider";
import {PageContentContainer} from "../styled/styled";
import {Box} from "@mui/material";

export const AgentsOverview = () => {
    const {featureFlags} = useAppState();

    const isAgentsEnabled = featureFlags?.[LDKeyAgents];

    if (!isAgentsEnabled) {
        return <PageContentContainer>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <h2>Agents feature is coming soon!</h2>
            </Box>
        </PageContentContainer>
    }

    return <iframe title={"agents-view"} src="https://frontend-agent-deployment.vercel.app" style={{
        paddingLeft: "240px",
        width: "100%",
        paddingTop: "64px",
        height: "98vh"
    }}/>
}
