import {get, post} from "./httpWrapper";
import {UserData} from "../hooks/useAuth";
import {ESimulationStatus, MarketScenario, SimulationMetadata, TokenPair} from "../state/types";
import {Dayjs} from "dayjs";
import Big from "big.js";
import {ApiKey} from "../views/user-profile/UserProfile";
import {remapAssets} from "./mappers";
import {SupportedChain} from "../views/wallets/WalletsOverview";

const hasuraApiUrl = process.env.REACT_APP_HASURA_API_URL;
const verifyWeb3AuthUrl = process.env.REACT_APP_VERIFY_AUTH_URL;


type GraphQLData = {
    operationName: string,
    query: string,
    variables?: any
};

export type CreditPlan = {
    created_at: string;
    credits: number;
    currency: string;
    description: string | null;
    id: string;
    is_active: boolean;
    name: string;
    price: number;
    stripe_price_id: string;
    updated_at: string;
};


const getUser = async (hasuraId: string) => {
    const userQuery = `
    query MyQuery {
        users(where: {firebase_uid: {_eq: "${hasuraId}"}}) {
          id
          firebase_uid
          name
          email
          notification_email
          wallet_address
          user_teams {
            id
          }
          organisations {
            id
          }      
        }
    }`

    const res = await executeQuery({operationName: "MyQuery", query: userQuery})
    return res.data.data.users[0]

}

const updateUser = async (id: string, values: { name: string, notification_email: string }) => {
    const userUpdateQuery = `
    mutation UpdateUser {
        update_users_by_pk(pk_columns: {id: "${id}"}, _set: {name: "${values.name}", notification_email: "${values.notification_email}"}) {
            email
            name
        }
    }
`
    const res = await executeQuery({
        operationName: "UpdateUser",
        query: userUpdateQuery,
    })

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message)
    }
    return res.data.data.update_users_by_pk

}

const getUserTeam = async (userId: string) => {
    const userQuery = `
   query MyQuery {
        user_team(where: {user_id: {_eq: "${userId}"}}) {
          id
          organisation_id
          team_id
          user {
            email
            name
            notification_email
          }
        }
}
`

    const res = await executeQuery({operationName: "MyQuery", query: userQuery})

    const isNotWhitelisted = res.data.errors?.[0]?.message === "Authentication hook unauthorized this request";

    if (isNotWhitelisted) {
        throw new Error("User is not whitelisted")
    } else {
        return res.data?.data?.user_team?.[0]
    }


}
const getAccessToken = async (idToken: string, publicKey: string) => {
    const data = {
        idToken,
        publicKey
    }
    return post(verifyWeb3AuthUrl!, data, true);
}

const executeQuery = async (data: GraphQLData) => {
    return post(hasuraApiUrl!, data);
};

const getSimulations = async () => {
    const simulationsQuery = `query GetSimulations {
        simulations(order_by: {created_at: desc}) {
            id
            organisation_id
            created_at
            status
            description
        }
    }`;
    return executeQuery({operationName: "GetSimulations", query: simulationsQuery})
};

const getGroupSimulations = async () => {

    const groupSimulationsQuery = `query GetGroupSimulations {
          group_simulations(order_by: {created_at: desc}) {
            frontend_state
            created_at
            created_by
            started_at
            finished_at
            agent_configs
            price_configs
            metadata
            description
            status_reason
            id
            status
            version
          }
    }`;
    return executeQuery({operationName: "GetGroupSimulations", query: groupSimulationsQuery})
};

const getSimulation = async (id: string) => {
    const simulationQuery = `
    query GetSimulation {
        simulations_by_pk(id: "${id}") {
          id
          created_at
          created_by
          description
          organisation_id
          status
          storage_url
          team_id
          metadata
  }
}`
    const simulationRes = await executeQuery({operationName: "GetSimulation", query: simulationQuery});
    return simulationRes.data.data.simulations_by_pk;
}

const optimizationModule = {
    type: "arakis",
    protocols: [
        {
            name: "Uniswap",
            parameters: [
                {
                    name: "P_Protocol_fee",
                    parametrization: "uniform",
                    inputDataType: "list[float,float]",
                    description: "Protocol parameter description..",
                    inputValues: [0.10, 0.25]
                },
                {
                    name: "P_fee_zero",
                    parametrization: "uniform",
                    inputDataType: "float",
                    description: "Protocol parameter description..",
                    inputValues: 0.5
                },
            ],
            metrics: [
                {name: "Protocol_Revenue", description: "desc"}, {
                    name: "TVK_and_Uncollected_fees",
                    description: "desc"
                }, {name: "trading_volume", description: "desc"},
            ],
            optimizationFunctions: [{name: "Function 1", description: "desc"},]
        },
        // ...other protocols
    ]
};


const getGroupSimulation = async (id: string) => {
    const groupSimulationQuery = `
        query GetGroupSimulation {
            group_simulations_by_pk(id: "${id}") {
              agent_configs
              agents_alias
              created_at
              created_by
              gcs_url
              description
              id
              metadata
              frontend_state
              organisation_id
              price_configs
              status
              status_reason
              system_notes
              team_id
              updated_at
              started_at
              finished_at
              version
            }
        }`
    const simulationRes = await executeQuery({operationName: "GetGroupSimulation", query: groupSimulationQuery});
    return simulationRes.data.data.group_simulations_by_pk;
}

const fetchAggregatedMetricsFromUri = async (uri: string) => {

    const res = await get(uri, true)
    return res?.data;
};

const getAggregatedMetricsForGroupSimulation = async (id: string) => {
    const query = `
            mutation GenerateAggregatedMetricsUri {
                generateAggregatedMetricsURI(arg1: {group_simulation_id: "${id}"}) {
                  message
                  uri
                  valid
                }
            }`
    const uriRes = await executeQuery({operationName: "GenerateAggregatedMetricsUri", query});
    if (uriRes.data.errors) {
        throw new Error(uriRes.data.errors[0].message)
    }
    return uriRes.data.data.generateAggregatedMetricsURI.uri;

}


const startSimulation = async (simulationId: string) => {
    const query = `
        mutation StartSimulation {
            startSimulation(arg1: {simulation_reference_id: "${simulationId}"}) {
                run_ids
            }
        }
    `
    return executeQuery({operationName: "StartSimulation", query});
};

const startGroupSimulation = async (groupSimulationId: string) => {
    const query = `
        mutation StartGroupSimulation {
            startGroupSimulation(arg1: {group_simulation_id: "${groupSimulationId}", version: 2}) {
              message
              valid
            }
        }
    `
    return executeQuery({operationName: "StartGroupSimulation", query});
};

const createSimulation = async (metadata: SimulationMetadata, user: UserData) => {

    const createSimulationQuery = `mutation CreateSimulation($metadata: jsonb = {}) {
              insert_simulations_one(object: 
                {
                description: "Description",
                metadata: $metadata,
                storage_url: "https://stoprage.almanak.co/simulations",
                organisation_id: "${user.organisationId}",
                created_by: "${user.id}",
                status: "pending",
                team_id: "${user.teamId}"
                updated_at: "${new Date().toUTCString()}"
                }
              ) {
                  id
                  created_at
                  metadata
                  frontend_state
                }
            }`
    return executeQuery({
        operationName: "CreateSimulation",
        query: createSimulationQuery,
        variables: {"metadata": metadata}
    })

};

export type GroupSimulation = {
    id?: string;
    isLoading?: boolean;
    gcs_url?: string;
    status?: ESimulationStatus;
    description?: string;
    created_at?: string;
    updated_at?: string;
    metadata?: any;
    agent_configs: any[];
    agents_alias?: string;
    price_configs: string[];
    frontend_state: SimulationMetadata;
    aggregated_metrics?: any;
    status_reason?: string;
    system_notes?: string;
    simulationMetricsUri?: string;
    started_at?: Date;
    finished_at?: Date;
    version?: number;
};


const createGroupSimulation = async (payload: GroupSimulation, user: UserData) => {

    const updatePayload = {
        ...payload,
        organisation_id: user.organisationId,
        created_by: user.id,
        team_id: user.teamId,
        created_at: new Date()
    }
    const createGroupSimulationQuery = `
            mutation CreateGroupSimulation($payload: group_simulations_insert_input = {}) {
                insert_group_simulations_one(object: $payload) {
                  id
                  metadata
                  frontend_state
                  description
                  created_at
                  status
                }
            }`
    return executeQuery({
        operationName: "CreateGroupSimulation",
        query: createGroupSimulationQuery,
        variables: {"payload": updatePayload}
    })

};

const updateSimulation = async (id: string, metadata: SimulationMetadata) => {

    const createSimulationQuery = `mutation UpdateSimulation($metadata: jsonb = {}) {
                                        update_simulations_by_pk(pk_columns: {id: "${id}"}, _set: {metadata: $metadata}) {
                                          id
                                        }
                                   }`

    return executeQuery({
        operationName: "UpdateSimulation",
        query: createSimulationQuery,
        variables: {"metadata": metadata}
    })
};

// const dynamicVariablesInQuery = `${metadata ? "$metadata: jsonb = {}," : ""}${frontend_state ? "$frontend_state: jsonb = {}," : ""}${price_configs ? "$price_configs: jsonb = {}," : ""}${agent_configs ? "$agent_configs: jsonb = {}," : ""}${description ? "$description: String = ''" : ""}`;
// const dynamicSetInQuery = `{${metadata ? "metadata: $metadata," : ""}${frontend_state ? "frontend_state: $frontend_state," : ""}${price_configs ? "price_configs: $price_configs," : ""}${agent_configs ? "agent_configs: $agent_configs," : ""}${description ? ("description: $description") : ""}}`;

//TODO: TYPE
const updateGroupSimulation = async (id: string, props: {
    frontend_state?: SimulationMetadata | any,
    price_configs?: string[] | null,
    metadata?: any,
    agent_configs?: any[]
}) => {

    const {frontend_state, price_configs, metadata, agent_configs} = props;


    const dynamicVariablesInQuery = `${metadata ? "$metadata: jsonb = {}," : ""}${frontend_state ? "$frontend_state: jsonb = {}," : ""}${price_configs ? "$price_configs: jsonb = {}," : ""}${agent_configs ? "$agent_configs: jsonb = {}," : ""}`;
    const dynamicSetInQuery = `{${metadata ? "metadata: $metadata," : ""}${frontend_state ? "frontend_state: $frontend_state," : ""}${price_configs ? "price_configs: $price_configs," : ""}${agent_configs ? "agent_configs: $agent_configs," : ""}}`;

    const createSimulationQuery = `mutation UpdateSimulation(${dynamicVariablesInQuery}) {
                                        update_group_simulations_by_pk(pk_columns: {id: "${id}"} _set: ${dynamicSetInQuery}) {
                                          id
                                          metadata
                                          frontend_state
                                          price_configs
                                          agent_configs
                                        }
                                   }`


    // const createSimulationQuery = `mutation UpdateSimulation($metadata: jsonb = {}, $frontend_state: jsonb = {}, $price_configs: jsonb = {}, $agent_configs: jsonb = {}) {
    //                                     update_group_simulations_by_pk(pk_columns: {id: "${id}"} _set: {frontend_state: $frontend_state, price_configs: $price_configs, metadata: $metadata, agent_configs: $agent_configs}) {
    //                                       id
    //                                       metadata
    //                                       frontend_state
    //                                       price_configs
    //                                       agent_configs
    //                                     }
    //                                }`

    let variables: any = {};

    if (metadata) {
        variables.metadata = metadata;
    }
    if (frontend_state) {
        variables.frontend_state = frontend_state;
    }
    if (price_configs) {
        variables.price_configs = price_configs;
    }
    if (agent_configs) {
        variables.agent_configs = agent_configs;
    }

    const res = await executeQuery({
        operationName: "UpdateSimulation",
        query: createSimulationQuery,
        variables: variables
    })

    return res.data?.data?.update_group_simulations_by_pk
};

type PriceTrajectoryOptions = {
    color: string;
}
type PriceTrajectory = {
    symbol1: string;
    symbol2: string;
    drift?: number[];
    volatility_scale: number;
    market_drift_mode?: string;
    max_percentage_change_historical_std_multiplier?: number;
    options: PriceTrajectoryOptions;
}
const getManyPriceData = (symbol1: string, symbol2: string, numberOfTrajectories: number, granularity: number, steps: number, priceTrajectories: PriceTrajectory[], start_block: number, end_block: number) => {

    const volatilityMode = "ewma"; // ewma | cccgarch | constant

    const query = `query GetManyPriceDataByTrajectory($priceTrajectories: [PriceTrajectories]) {
                     getManyPriceData(arg1: {start_block: ${start_block}, end_block: ${end_block}, granularity: ${granularity}, numberOfTrajectories: ${numberOfTrajectories}, steps: ${steps}, volatilityMode: "${volatilityMode}", priceTrajectories: $priceTrajectories}) {
                       data {
                         gcs_uri
                         price_config_hashkey
                         options {
                           color
                         }
                       }
                     }
                   }`

    return executeQuery({
        operationName: "GetManyPriceDataByTrajectory",
        query,
        variables: {"priceTrajectories": priceTrajectories}
    })
}

const getManyPriceDataV2 = async (symbol1: string, symbol2: string, numberOfTrajectories: number, granularity: number, steps: number, start_block: number, end_block: number, color: string, volatilityScale: number, marketDriftMode: string, drift?: [number, number]) => {

    const volatilityMode = "ewma"; // ewma | cccgarch | constant

    const query1 = `
    query GetManyPriceDataByTrajectory($drift: [Float]) {
        getManyPriceDataV2(arg1: {drift: $drift, market_drift_mode: "${marketDriftMode}", enable_black_swan_event: false, end_block: ${end_block}, granularity: ${granularity}, start_block: ${start_block}, steps: ${steps}, symbol1: "${symbol1}", symbol2: "${symbol2}", trajectories: ${numberOfTrajectories}, volatilityMode: "${volatilityMode}", volatility_scale: ${volatilityScale}, options: {color: "${color}"}}) {
          data {
            gcs_uri
            options {
              color
            }
            price_config_hashkey
            asset_options {
            options {
              color
            }
            symbol
            }  
          }
        }
    }`

    const res = await executeQuery({
        operationName: "GetManyPriceDataByTrajectory",
        query: query1,
        variables: {"drift": drift}
    })
    if (res.data?.errors?.[0].message) {
        throw new Error(res.data?.errors?.[0].message);
    }
    if (res.data?.data?.getManyPriceDataV2?.data) {
        return res.data?.data?.getManyPriceDataV2?.data;
    } else {
        throw new Error("Something went wrong. Please contact support.")
    }
}

const getManyPriceDataV3 = async (assetPairs: TokenPair[] | null, numberOfTrajectories: number, granularity: number, steps: number, start_block: number, end_block: number, scenario: MarketScenario) => {

    if (!assetPairs) {
        throw new Error("No asset pairs selected");
    }
    const volatilityMode = "cccgarch"; // ewma | cccgarch | constant

    const config = {
        "config": {},
        "assets": assetPairs.map(pair => {
            return {
                "symbol": [
                    pair.token0_name,
                    pair.token1_name
                ],
                "config": {
                    "drift": scenario.drift, //takes priority over the global drift
                    "volatility_scale": scenario.volatilityMultiplier,
                    "market_drift_mode": scenario.marketDriftMode,
                    "enable_black_swan_event": false,
                },
                "options": { //take anything and return to frontend
                    "color": scenario.color
                }
            }
        })
    }


    const query = `mutation GetManyPriceDataByV3($drift: [Float], $assets: [assetsTypeV3], $config: assetsTypeV3Config = {} ) {getManyPriceDataV3Async(arg1: {drift: $drift, end_block: ${end_block}, granularity: ${granularity}, start_block: ${start_block}, steps: ${steps}, trajectories: ${numberOfTrajectories}, volatilityMode: \"${volatilityMode}\", assets: $assets, config: $config})}`

    const res = await executeQuery({
        operationName: "GetManyPriceDataByV3",
        query: query,
        variables: config
    })
    if (res.data?.errors?.[0].message) {
        throw new Error(res.data?.errors?.[0].message);
    }
    if (res.data?.data?.getManyPriceDataV3Async) {
        return res.data?.data?.getManyPriceDataV3Async;
    } else {
        throw new Error("Something went wrong. Please contact support.")
    }
}


const getManyPriceDataV3Results = async (id: string) => {
    const query = `query getManyPriceDataV3Async {
        getManyPriceDataV3Async(id: "${id}") {
          created_at
          errors
          id
          output {
            data {
              gcs_uri
              options {
                color
              }
              price_config_hashkey
              asset_options {
                options {
                  color
                }
                symbol
              }
            }
          }
        }
    }`

    const res = await executeQuery({
        operationName: "getManyPriceDataV3Async",
        query: query,
    })


    if (res.data?.errors?.[0].message) {
        throw new Error(res.data?.errors?.[0].message);
    }
    if (res.data?.data?.getManyPriceDataV3Async?.output?.data) {
        return res.data?.data?.getManyPriceDataV3Async.output.data;
    } else {
        if (res.data?.data?.getManyPriceDataV3Async?.errors) {
            return {inProgress: true, error: res.data?.data?.getManyPriceDataV3Async?.errors?.error};
        }
        return {inProgress: true}
        // throw new Error("Something went wrong. Please contact support.")
    }

}

const getAvailablePools = () => {

    const query = `query GetTokenPairPoolData {
                        getTokenPairPoolData {
                          data {
                            token1_name
                            token1_address
                            token0_name
                            token0_address
                            symbol
                            pools {
                              fee
                              pool
                              token0_address
                              token0_name
                              token0_symbol
                              token1_address
                              token1_name
                              token1_symbol
                              number_of_minters
                              number_of_traders
                            }
                          }
                        }
                    }`

    return executeQuery({
        operationName: "GetTokenPairPoolData",
        query
    })
}
const getPriceSimulationData = (symbol1: string, symbol2: string, granularity: number, marketDrift: number, steps: number) => {
    const volatilityMode = "cccgarch"; // ewma | cccgarch

    const priceSimulationQuery = `query GetPriceData {
                                      getPriceData(arg1: {granularity: ${granularity}, marketDrift: ${marketDrift}, steps: ${steps}, symbol1: "ETH", symbol2: "USDT", volatilityMode: "${volatilityMode}"}) {
                                          prices {
                                            historical
                                            simulated
                                            pair
                                          }
                                      }
                                  }`

    return executeQuery({operationName: "GetPriceData", query: priceSimulationQuery})
}

// Function to convert the pair string to an array
const parsePair = (pairString: string) => {
    return JSON.parse(pairString);
}
// export const convertData = (inputData: any, options: any) => {
//     //console.log("convert data inputData", inputData);
//     const outputData: any = {};
//     const pairString = Object.keys(inputData.prices.simulated)[0];
//     const pairArray = parsePair(pairString);
//
//     for (const mainKey in inputData) {
//         if (inputData.hasOwnProperty(mainKey)) {
//             outputData[mainKey] = {};
//             outputData[mainKey]['pair'] = pairArray;
//
//             for (const subKey in inputData[mainKey]) {
//                 if (inputData[mainKey].hasOwnProperty(subKey)) {
//                     outputData[mainKey][subKey] = inputData[mainKey][subKey][pairString] || [];
//                 }
//             }
//         }
//     }
//
//     //console.log("convert data outputData", outputData);
//
//
//     return {...outputData, ...options};
// }

export const convertData = (inputData: any, options: any) => {
    const outputData: any = {};
    const pairsSet = new Set();

    // Collect all unique pairs
    for (const mainKey in inputData) {
        if (inputData.hasOwnProperty(mainKey)) {
            for (const subKey in inputData[mainKey]) {
                if (inputData[mainKey].hasOwnProperty(subKey)) {
                    Object.keys(inputData[mainKey][subKey]).forEach(pairString => {
                        pairsSet.add(pairString);
                    });
                }
            }
        }
    }

    const pairsArray = Array.from(pairsSet).map(pairString => JSON.parse(pairString as string));

    for (const mainKey in inputData) {
        if (inputData.hasOwnProperty(mainKey)) {
            outputData[mainKey] = {};
            outputData[mainKey]['pairs'] = pairsArray;

            for (const subKey in inputData[mainKey]) {
                if (inputData[mainKey].hasOwnProperty(subKey)) {
                    outputData[mainKey][subKey] = {};

                    Array.from(pairsArray).forEach(pairString => {
                        // Correctly mapping the pairString with input data
                        const dataForPair = inputData[mainKey][subKey][pairString];
                        if (dataForPair) {
                            outputData[mainKey][subKey][pairString] = dataForPair;
                        }
                    });
                }
            }
        }
    }


    return {...outputData, ...options};
}


export const convertDataForMultiplePairs = (inputData: any, options: any) => {
    const outputData: any = {};

    const pairs = Object.keys(inputData.prices.simulated);
    pairs.forEach(pairString => {
        const pairArray = parsePair(pairString);
        outputData[pairString] = {}; // Create a nested object for each pair

        for (const mainKey in inputData) {
            if (inputData.hasOwnProperty(mainKey)) {
                outputData[pairString][mainKey] = {};
                outputData[pairString][mainKey]['pair'] = pairArray;

                for (const subKey in inputData[mainKey]) {
                    if (inputData[mainKey].hasOwnProperty(subKey) && inputData[mainKey][subKey][pairString]) {
                        outputData[pairString][mainKey][subKey] = inputData[mainKey][subKey][pairString];
                    } else {
                        outputData[pairString][mainKey][subKey] = [];
                    }
                }
            }
        }
    });

    return {...outputData, ...options};
};

const fetchPriceTrajectoryResults = async (url: string, options: any) => {
    return await get(url, true)
    // const res = await get(url, true)
    // return convertData(res?.data, options);
};

const getAgentsLibrary = () => {
    const agentsLibraryQuery = `
      query GetAgentCatalog {
        agent_catalog {
          author
          available
          agents_config
          class
          created_at
          total_usd_equivalent
          description
          id
          version
        }
    }`
    return executeQuery({operationName: "GetAgentCatalog", query: agentsLibraryQuery})
}

const getAgentById = (id: string) => {
    const query = `query GetAgentById {
                     agent_catalog_by_pk(id: "${id}") {
                       agents_config
                       author
                       class
                       created_at
                       description
                       id
                       total_usd_equivalent
                     }
                   }`
    return executeQuery({operationName: "GetAgentById", query})
}

const getAvailableTokens = () => {
    //TODO: replace when token endpoint is available
    return [
        {
            token: "USDT",
            address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            isNative: false,
            decimalPlaces: 6,
            base: Big(10),
            exponent: Big(6)
        },
        {
            token: "WETH",
            address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
            isNative: false,
            decimalPlaces: 18,
            base: Big(10),
            exponent: Big(18)
        },
        {
            token: "WBTC",
            address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
            isNative: false,
            decimalPlaces: 8,
            base: Big(10),
            exponent: Big(6)
        },
        {
            token: "ETH",
            address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
            isNative: true,
            nativeLabel: "Uniswap", decimalPlaces: 18, base: Big(10), exponent: Big(18)
        },
        {
            token: "BNT",
            address: "0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C",
            isNative: false,
            decimalPlaces: 18, base: Big(10), exponent: Big(18)
        },
        {
            token: "LINK",
            address: "0x514910771af9ca656af840dff83e8264ecf986ca",
            isNative: false,
            decimalPlaces: 18, base: Big(10), exponent: Big(18)
        }
    ];
}

const fetchMintersAndTraders = async (address: string) => {
    const query = `
        query FetchMintersAndTraders {
            fetchNumberOfTradersPerUniswapV3Pool(argData: {pool_address: "${address}"}) {
              number_of_traders
            }
            fetchNumberOfLpProvidersPerUniswapV3Pool(argData: {pool_address: "${address}"}) {
              number_of_minters
            }
        }`
    return executeQuery({operationName: "FetchMintersAndTraders", query})
}

const generateWallets = async (numberOfWallets: number) => {
    const query = `
        mutation GenerateWallets {
            generateManyWalletAddress(arg1: {count: ${numberOfWallets}}) {
                wallet_address_array
            }
        }`
    return executeQuery({operationName: "GenerateWallets", query})
}

const getAvailableTokenPairs = async () => {

    const query = `
            query GetCommonAssetPairs {
                common_us_asset_pairs {
                  Instrument
                  Timeframe
                  earliest_date
                  end_date
                  token1
                  token2
                }
    }`
    const res = await executeQuery({operationName: "GetCommonAssetPairs", query})

    return remapAssets(res.data.data.common_us_asset_pairs);
};

const getBlockHeightByTime = (date: Dayjs) => {
    const query = `
        query GetBlockHeightByTime {
            getBlockHeightByTime(arg1: {timestamp: "${date}"}) {
              block_height
            }
        }`

    return executeQuery({operationName: "GetBlockHeightByTime", query})

}

//Generate token to open docs:

const getDocsAccessToken = async (): Promise<{ customToken: string }> => {
    const query = `
        mutation GenerateCustomSigninToken {
        generateCustomSigninToken {
            customToken
        }
    }`

    const res = await executeQuery({operationName: "GenerateCustomSigninToken", query});
    return res.data.data.generateCustomSigninToken;
}

const getUserCreditBalance = async (): Promise<any> => {
    const query = `
    query GetCreditBalance {
        getCreditBalance {
          credit_balance
        }
      }`

    const res = await executeQuery({operationName: "GetCreditBalance", query});
    return res?.data?.data?.getCreditBalance?.credit_balance ?? 0;

}

const getCreditPackages = async (): Promise<CreditPlan[]> => {

    const query = `
        query GetCreditPackages {
                credit_packages {
                  created_at
                  credits
                  currency
                  description
                  id
                  is_active
                  name
                  price
                  stripe_price_id
                  updated_at
                }
        }`

    const res = await executeQuery({operationName: "GetCreditPackages", query});

    return res?.data?.data?.credit_packages ?? [];
};

const purchaseCreditPackage = async (creditPackageId: string): Promise<any> => {


    const isProduction = process.env.REACT_APP_ENVIRONMENT === "production"
    const errorUrl = isProduction ? "https://app.almanak.co/buy-credits/error" : "https://stage.almanak.co/buy-credits/error";
    const successUrl = isProduction ? "https://app.almanak.co/buy-credits/success" : "https://stage.almanak.co/buy-credits/success";

    const query = `
        mutation CreatePaymentLink {
          createPaymentLink(arg1: {
              cancel_url: "${errorUrl}", 
              credit_package_id: "${creditPackageId}",
              success_url: "${successUrl}"
          }) {
            message
            url
            valid
          }
        }`

    const res = await executeQuery({operationName: "CreatePaymentLink", query});

    console.log("res", res);
    const url = res?.data?.data?.createPaymentLink?.url;
    if (!url) {
        throw new Error("Error creating payment link");
    }
    return url;
}

const getCostAndDurationEstimate = async (group_simulation_id: string): Promise<any> => {
    const query = `
    query GetCostAndDurationEstimate {
        getCostAndDurationEstimate(arg1: {group_simulation_id: "${group_simulation_id}"}) {
          estimated_cost
          estimated_duration
          message
          valid
        }
      }`

    return executeQuery({operationName: "GetCostAndDurationEstimate", query});
}

const getApiKey = async (): Promise<ApiKey> => {
    const query = `
        query GetApiKey {
            user_api_key(where: {active: {_eq: true}}) {
              api_key
              created_at
              jwt
              active
            }
        }`
    const res = await executeQuery({operationName: "GetApiKey", query});
    return res?.data?.data?.user_api_key?.[0] ?? null
}

const resetApiKey = async (): Promise<ApiKey> => {
    const query = `
        mutation ResetApiKey {
             resetApiKey {
               active
               api_key
               created_at
               jwt
             }
        }`

    const res = await executeQuery({operationName: "ResetApiKey", query});
    return res?.data?.data?.resetApiKey ?? null
}

const getSupportedChains = async (): Promise<SupportedChain[]> => {
    const query = `
       query GetSupportedChains {
        supported_chain {
          block_explorer_url
          chain_id
          chain_id_hex
          chain_name_space
          currency
          is_active
          is_layer2
          is_testnet
          minimum_comfirmation_block_number
          name
          rpc_url
        }
} `

    const res = await executeQuery({operationName: "GetSupportedChains", query});
    return res?.data?.data?.supported_chain ?? null
}

const getArtifactLibraryItems = async () => {
    const query = `
      query ArtifactLibraryItems {
        artifacts {
            id
            name
            metadata
            date_created
            description
            author_user {
              email
              name
            }
            latest_version_artifact {
              id
              date_created
              description
              is_public
              metadata
              name
              uri
              artifact_files {
                id
                uri
                authenticated_url
              }
              author_user {
                email
                name
              }
            }
               artifact_versions {
                id
                date_created
                description
                is_public
                metadata
                name
                artifact_files {
                  id
                  uri
                  authenticated_url
                }
                author_user {
                  email
                  name
                }
              }
        }
    }
    
`;

    const res = await executeQuery({operationName: "ArtifactLibraryItems", query});
    return res?.data?.data?.artifacts;
}

const createSmartWallet = async (chainId: string, name: string, type: string) => {
    // createSmartWallet(arg1: {chain_id: "${chainId}", name: "${name}", type: "${type}") {

    const mutationQuery = `
        mutation CreateSmartWallet {
          createSmartWallet(arg1: {chain_id: "${chainId}", name: "${name}", type: "${type}"}) {
            message
            smartWalletDetails {
              id
              chain_id
              eoa_address
              is_active
              is_in_used
              is_layer2
              is_testnet
              name
              type
              user_id
              chain {
                block_explorer_url
                chain_id
                chain_id_hex
                chain_name_space
                currency
                is_active
                is_layer2
                is_testnet
                minimum_comfirmation_block_number
                name
                rpc_url
              }
            }
            valid
          }
        }    
    `

    const res = await executeQuery({
        operationName: "CreateSmartWallet",
        query: mutationQuery,
    })

    console.log("createSmartWallet res", res);

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message)
    }
    return res.data.data.createSmartWallet


}

const refreshJwtToken = async () => {

    const mutationQuery = `
          mutation RefreshJwtToken {
             refreshJwtToken {
                 expirationTime
                 message
                 token
                 valid
             }
         }
    `


    //response
    // {
    //     "data": {
    //     "refreshJwtToken": {
    //         "expirationTime": "Thu, 29 Aug 2024 06:43:53 GMT",
    //             "message": "Token refreshed successfully",
    //             "token": "...",
    //             "valid": true
    //     }
    // }
    // }


    const res = await executeQuery({
        operationName: "RefreshJwtToken",
        query: mutationQuery,
    })

    console.log("RefreshJwtToken res", res);

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message)
    }
    return res.data.data.refreshJwtToken.token

}


const getSmartWalletById = async (id?: string) => {
    if (!id) {
        return null
    }
    const query = `
            query GetSmartWalletById {
              smart_contract_wallet_by_pk(id: "${id}") {
                id
                address
                chain_id
                created_at
                creation_payload
                is_active
                is_in_used
                is_layer2
                is_testnet
                name
                tx_hash
                type
                updated_at
                user_id
                external_own_accounts {
                     address
                     gcs_url
                     smart_contract_wallet_id
                     user_id
                }
              }
            }
    `

    const res = await executeQuery({operationName: "GetSmartWalletById", query});
    return res?.data?.data?.smart_contract_wallet_by_pk;


}

const requestFreeCredits = async () => {
    const query = `
        mutation RequestFreeCredits {
          requestFreeCredits {
            message
            valid
          }
        }`

    const res = await executeQuery({
        operationName: "RequestFreeCredits",
        query: query,
    })

    return res.data.data.requestFreeCredits;
}

const updateSmartWallet = async (walletId: string, txHash: string, address: string) => {
    const query = `mutation UpdateSmartWalletWithTXHash {
        updateSmartWallet(arg1: {address: "${address}", smart_wallet_id: "${walletId}", txhash: "${txHash}"})
        {
          message
          valid
        }
    }`

    const res = await executeQuery({
        operationName: "UpdateSmartWalletWithTXHash",
        query: query,
    })


    console.log("updateSmartWallet res", res);
    if (res.data.errors) {
        throw new Error(res.data.errors[0].message)
    }
    return res.data.data.updateSmartWallet

}
const getSmartWallets = async (): Promise<any> => {
    const query = `
        query GetSmartWallets {
          smart_contract_wallet {
            user_id
            updated_at
            type
            tx_hash
            name
            is_testnet
            is_layer2
            is_in_used
            is_active
            id
            created_at
            creation_payload
            chain_id
            address
            external_own_accounts {
              address
              gcs_url
              smart_contract_wallet_id
              user_id
            }
          }
        }
    `

    const res = await executeQuery({operationName: "GetSmartWallets", query});
    return res?.data?.data?.smart_contract_wallet;

}

export {
    getAccessToken,
    executeQuery,
    createSimulation,
    createGroupSimulation,
    getSimulation,
    getGroupSimulation,
    getSimulations,
    getGroupSimulations,
    startSimulation,
    startGroupSimulation,
    updateSimulation,
    updateGroupSimulation,
    getPriceSimulationData,
    getUser,
    getUserTeam,
    getAgentsLibrary,
    fetchPriceTrajectoryResults,
    getManyPriceData,
    getManyPriceDataV2,
    getManyPriceDataV3,
    getManyPriceDataV3Results,
    getAvailablePools,
    getAvailableTokens,
    fetchMintersAndTraders,
    getAgentById,
    generateWallets,
    getAvailableTokenPairs,
    getBlockHeightByTime,
    getAggregatedMetricsForGroupSimulation,
    fetchAggregatedMetricsFromUri,
    getDocsAccessToken,
    updateUser,
    getUserCreditBalance,
    getCostAndDurationEstimate,
    getApiKey,
    resetApiKey,
    getArtifactLibraryItems,
    getCreditPackages,
    purchaseCreditPackage,
    getSupportedChains,
    createSmartWallet,
    getSmartWalletById,
    getSmartWallets,
    updateSmartWallet,
    refreshJwtToken,
    requestFreeCredits
}
