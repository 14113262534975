import Box from "@mui/material/Box";
import * as React from "react";
import {useAppState} from "../../state/AppStateProvider";
import {useTheme, Card, CardContent, Typography, CircularProgress} from "@mui/material";
import {PageContentContainer} from "../styled/styled";
import useSWR from "swr";
import {
    CreditPlan,
    getCreditPackages,
    getUserCreditBalance,
    purchaseCreditPackage,
    requestFreeCredits
} from "../../api/hasura";
import {LoadingButton} from "@mui/lab";
import {useMemo} from "react";

const getDisplayCurrency = (currency: string) => {
    switch (currency) {
        case "USD":
            return "$";
        case "EUR":
            return "€";
        default:
            return currency;
    }
}
export const BuyCredits = () => {
    const {setSnackBar} = useAppState();
    const theme = useTheme();


    const [purchasingPlanId, setPurchasingPlanId] = React.useState<null | string>(null);

    const [isClaimingFreeCredits, setIsClaimingFreeCredits] = React.useState(false);
    const isFreeCreditsEnabled = true;

    const {
        data: creditPackages,
        isLoading: isCreditBalanceLoading
    } = useSWR(`multiverse-credit-plans`, getCreditPackages);

    const {
        mutate: mutateCreditBalance,
    } = useSWR(`user-credits`, getUserCreditBalance);



    const handlePurchase = async (plan: CreditPlan) => {

        setPurchasingPlanId(plan.id);
        try {
            console.log(`Purchasing plan: ${plan.name}`);
            const purchaseUrl = await purchaseCreditPackage(plan.id);
            console.log("res", purchaseUrl);

            window.open(purchaseUrl, "_self");
        } catch (err: any) {
            console.error(err);
            setSnackBar({open: true, message: "Could not process purchase, please contact support", severity: "error"});
        } finally {
            setPurchasingPlanId(null);
        }
    };

    const claimFreeCredits = async () => {
        console.log("claim");

        setIsClaimingFreeCredits(true)
        try {
            const res = await requestFreeCredits();
            console.log("res", res);
            setSnackBar({open: true, message: res.message, severity: res.valid ? "success" : "error"});
        } catch (e: any) {
            console.error(e);
            setSnackBar({open: true, message: e.message, severity: "error"});
        } finally {
            setIsClaimingFreeCredits(false)
            mutateCreditBalance();
        }
    }


    const RealCreditsPurchaseForm = useMemo(() => {
        return isCreditBalanceLoading ? (
            <CircularProgress sx={{m: 4}}/>
        ) : (
            creditPackages?.map((plan, index) => (
                <Card key={index} sx={{
                    width: '320px',
                    padding: "16px",
                    border: "1px solid #e0e0e0",
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px"
                }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom sx={{fontWeight: "bold"}}>
                            {plan.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom sx={{marginBottom: "16px"}}>
                            {plan.description}
                        </Typography>

                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "center"
                        }}>
                            <div style={{
                                fontSize: "28px",
                                padding: "4px",
                                fontWeight: "bold",
                                color: "grey"
                            }}>{getDisplayCurrency(plan.currency)}</div>
                            <div style={{fontSize: "42px", fontWeight: "bold"}}>{plan.price}</div>
                        </Box>
                        <Typography variant="body1" color="textPrimary" sx={{my: 2}}>
                            {plan.credits} Multiverse Credits
                        </Typography>
                        <LoadingButton
                            sx={{
                                marginTop: "16px",
                                padding: theme.spacing(1.5, 3),
                                fontWeight: "bold"
                            }}
                            loading={purchasingPlanId === plan.id}
                            disabled={!!purchasingPlanId}
                            variant="contained"
                            fullWidth
                            onClick={() => handlePurchase(plan)}
                        >
                            Purchase
                        </LoadingButton>
                    </CardContent>
                </Card>
            ))
        );
    }, [creditPackages, isCreditBalanceLoading, purchasingPlanId, handlePurchase, theme]);



    const FreeCreditsPurchaseForm = useMemo(() => {
        return <Box>
            <Card sx={{
                width: '320px',
                padding: "16px",
                border: "1px solid #e0e0e0",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px"
            }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom sx={{fontWeight: "bold"}}>
                        Free Credits
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{marginBottom: "16px"}}>
                        Get free credits to try out Almanak. Available once a day.
                    </Typography>

                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "center"
                    }}>
                        {/*<div style={{*/}
                        {/*    fontSize: "28px",*/}
                        {/*    padding: "4px",*/}
                        {/*    fontWeight: "bold",*/}
                        {/*    color: "grey"*/}
                        {/*}}>{getDisplayCurrency(plan.currency)}</div>*/}
                        <div style={{fontSize: "42px", fontWeight: "bold"}}>0$</div>
                    </Box>
                    <Typography variant="body1" color="textPrimary" sx={{my: 2}}>
                        300 Almanak Credits
                    </Typography>
                    <LoadingButton
                        sx={{
                            marginTop: "16px",
                            padding: theme.spacing(1.5, 3),
                            fontWeight: "bold"
                        }}
                        loading={isClaimingFreeCredits}
                        variant="contained"
                        fullWidth
                        onClick={claimFreeCredits}
                    >
                        Claim Free Credits
                    </LoadingButton>
                </CardContent>
            </Card>
        </Box>
    }, [isClaimingFreeCredits]);

    return (
        <PageContentContainer>
            <Box sx={{padding: theme.spacing(4)}}>
                <h2>
                    Purchase Multiverse Credits
                </h2>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 4,
                        justifyContent: 'center',
                    }}
                >

                    {isFreeCreditsEnabled ? FreeCreditsPurchaseForm : RealCreditsPurchaseForm}
                </Box>
            </Box>
        </PageContentContainer>
    );
};
