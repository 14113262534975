import {Web3Auth} from "@web3auth/modal";
import {CHAIN_NAMESPACES, SafeEventEmitterProvider, WALLET_ADAPTERS} from "@web3auth/base";
import {MetamaskAdapter} from "@web3auth/metamask-adapter";
import {TorusWalletAdapter} from "@web3auth/torus-evm-adapter";
import {getPublicCompressed} from "@toruslabs/eccrypto";
import {useAppState} from "../state/AppStateProvider";
import jwt from 'jwt-decode'
import * as LDClient from 'launchdarkly-js-client-sdk';


import {getAccessToken, getUserTeam, refreshJwtToken} from "../api/hasura";
import EthereumRpc from "../auth/web3RPC";
import {useNavigate} from "react-router-dom";
import LogRocket from "logrocket";
import axios from "axios";
import {EthereumPrivateKeyProvider} from "@web3auth/ethereum-provider";
import {CoinbaseAdapter} from "@web3auth/coinbase-adapter";

const clientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID;

export type UserData = {
    isWhitelisted: boolean,
    id: string,
    name: string;
    publicKey: string,
    isOnboarded: boolean,
    organisationId: string,
    teamId: string,
    "https://hasura.io/jwt/claims": {
        "x-hasura-default-role": string,
        "x-hasura-allowed-roles": string[],
        "x-hasura-user-id": string,
        "x-hasura-user-platform-id": string,
    },
    iss: string,
    "aud": "starry-agency-339312",
    auth_time: number,
    user_id: string,
    email?: string,
    notification_email?: string;
    email_verified: boolean,
    firebase?: {
        identities: {
            email: string[]
        },
        sign_in_provider: string
    },

}
const useAuth = () => {
    const {
        provider,
        web3Auth,
        setProvider,
        setWeb3Auth,
        setUser,
        setIsWalletConnecting,
        setSnackBar,
        setReloadCreditBalance,
        cliAuthPort,
        cliAuthentication,
        setFeatureFlags
    } = useAppState();
    const navigate = useNavigate();

    const getAccounts = async (provider: SafeEventEmitterProvider) => {
        if (!provider) {
            throw new Error("Provider not initialized yet");
        }
        const rpc = new EthereumRpc(provider);
        return await rpc.getAccounts();
    };

    const initFirebaseSession = async (idToken: string, userAddress?: string) => {
        if (!idToken || !userAddress) {
            web3Auth?.logout()
            setSnackBar({
                open: true,
                message: "No id token or no user address defined. Logged user out",
                severity: "error"
            });
            throw new Error("No id token or no user address defined")
        }
        return await getAccessToken(idToken, userAddress);
    }

    const initSession = async (web3Auth: Web3Auth) => {
        if (!web3Auth || !web3Auth.provider) {
            throw new Error("Web3Auth could was not initialized")
        }

        setIsWalletConnecting(true);
        const userAuthInfo = (await web3Auth.authenticateUser());
        const idToken = userAuthInfo.idToken;


        let keyToGetToken;

        //Dealing with social login
        if (web3Auth.connectedAdapterName === "openlogin") {
            try {
                const app_scoped_privkey = await web3Auth.provider?.request({
                    method: "eth_private_key",
                });


                // @ts-ignore
                keyToGetToken = getPublicCompressed(Buffer.from(app_scoped_privkey.padStart(64, "0"), "hex")).toString("hex");

            } catch (err) {
                //console.log("failed to get public compressed!", err);
                setIsWalletConnecting(false);
            }

            //Dealing with external wallet
        }

        const userPublicKey = (await getAccounts(web3Auth.provider))[0];

        const firebaseSession = await initFirebaseSession(idToken, keyToGetToken ?? userPublicKey);
        localStorage.setItem("accessToken", firebaseSession.data.verifyWeb3Auth.jwt)


        let userFromJwt: any = jwt(firebaseSession.data.verifyWeb3Auth.jwt);
        let userId = userFromJwt["https://hasura.io/jwt/claims"]["x-hasura-user-platform-id"];
        const userOrganization = await getUserTeam(userId);

        if (cliAuthentication) {
            try {
                await axios.post(`http://localhost:${cliAuthPort}/`, {
                    jwt: firebaseSession.data.verifyWeb3Auth.jwt,
                })
            } catch (error: any) {
                //console.log("Error when calling ", `http://localhost:${cliAuthPort}/`)
                console.error("Error: ", error)
                setSnackBar({message: "Error: " + error?.message, open: true, severity: "error"})
            }
        }
        //only record user sessions in production
        //only track launch darkly flags in production
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
            LogRocket.identify(userId, {
                name: userFromJwt.name,
                email: userFromJwt.email,
            });
        }

        if (process.env.REACT_APP_LAUNCHDARKLY_ID) {
            const LDUser = {
                "kind": "user",
                "key": userId,
                "name": userFromJwt.name,
                "email": userFromJwt.email
            };
            const ldclient = LDClient.initialize(process.env.REACT_APP_LAUNCHDARKLY_ID, LDUser);
            ldclient.on("ready", () => {
                const flagData = ldclient.allFlags();
                setFeatureFlags(flagData);
            });
        }

        setUser({
            ...userFromJwt,
            ...userOrganization?.user,
            id: userId,
            publicKey: userPublicKey as string,
            isOnboarded: true,
            organisationId: userOrganization?.organisation_id,
            teamId: userOrganization?.team_id,
            isWhitelisted: true
        })
        setReloadCreditBalance(true);
        setIsWalletConnecting(false);
    };


    const initWeb3Auth = async () => {
        if (!clientId) {
            throw new Error("WEB3AUTH_CLIENT_ID env variable is undefined");
        }

        try {

            const sepoliaChainId = "0xaa36a7";

            const chainId = process.env.REACT_APP_CHAIN_ID ?? sepoliaChainId;
            const rpcTarget = process.env.REACT_APP_RPC_TARGET ?? "https://rpc.ankr.com/eth";

            const web3AuthNetwork = process.env.REACT_APP_WEB3AUTH_NETWORK ?? "sapphire_mainnet"
            const sessionTime = 86400;

            const chainConfig = {
                chainId: chainId,
                rpcTarget: rpcTarget,
                chainNamespace: CHAIN_NAMESPACES.EIP155,
                displayName: "Ethereum Mainnet",
                blockExplorerUrl: "https://etherscan.io/",
                ticker: "ETH",
                tickerName: "ETH",
                logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=031",
            };

            const privateKeyProvider = new EthereumPrivateKeyProvider({config: {chainConfig}});


            const web3auth = new Web3Auth({
                clientId,
                privateKeyProvider: privateKeyProvider,
                chainConfig: {
                    chainNamespace: CHAIN_NAMESPACES.EIP155,
                    chainId,
                    rpcTarget,
                },
                uiConfig: {
                    // @ts-ignore
                    theme: "dark",
                    // loginMethodsOrder: ["google", "github"],
                    defaultLanguage: "en",
                    appLogo: "https://stage.almanak.co/logo-white.png",
                },
                // @ts-ignore
                web3AuthNetwork,
            });

            const coinbaseAdapter = new CoinbaseAdapter({
                clientId,
                sessionTime,
                chainConfig: {
                    chainNamespace: CHAIN_NAMESPACES.EIP155,
                    chainId,
                    rpcTarget,
                },
                // @ts-ignore
                web3AuthNetwork
            });
            web3auth.configureAdapter(coinbaseAdapter);

            coinbaseAdapter.setAdapterSettings({
                sessionTime,
                chainConfig: {
                    chainNamespace: CHAIN_NAMESPACES.EIP155,
                    chainId,
                    rpcTarget,
                },
                // @ts-ignore
                web3AuthNetwork
            });


            const torusWalletAdapter = new TorusWalletAdapter({
                adapterSettings: {
                    buttonPosition: "bottom-left",
                },
                loginSettings: {
                    verifier: "google",
                },
                initParams: {
                    buildEnv: "testing",
                },
                chainConfig: {
                    chainNamespace: CHAIN_NAMESPACES.EIP155,
                    chainId,
                    rpcTarget,
                    // Avoid using public rpcTarget in production.
                    // Use services like Infura, Quicknode etc
                    displayName: "Ethereum Mainnet",
                    ticker: "ETH",
                    tickerName: "Ethereum",
                },
                clientId,
                sessionTime,
                // @ts-ignore
                web3AuthNetwork,
            });

            web3auth.configureAdapter(torusWalletAdapter);

            torusWalletAdapter.setAdapterSettings({
                sessionTime,
                chainConfig: {
                    chainNamespace: CHAIN_NAMESPACES.EIP155,
                    chainId,
                    rpcTarget,
                },
                // @ts-ignore
                web3AuthNetwork,
            });


            const metamaskAdapter = new MetamaskAdapter({
                clientId,
                sessionTime: 86400, // 24 hours in seconds
                web3AuthNetwork: "sapphire_mainnet",
                chainConfig: {
                    chainNamespace: CHAIN_NAMESPACES.EIP155,
                    chainId,
                    rpcTarget,
                },
            });

            web3auth.configureAdapter(metamaskAdapter);


            // const walletConnectModal = new WalletConnectModal({ projectId: walletConnectProjectId });
            // const walletConnectV2Adapter = new WalletConnectV2Adapter({
            //     // adapterSettings: { qrcodeModal: walletConnectModal, ...defaultWcSettings.adapterSettings },
            //     // loginSettings: { ...defaultWcSettings.loginSettings },
            // });
            //
            // web3auth.configureAdapter(walletConnectV2Adapter);


            setWeb3Auth(web3auth);

            await web3auth.initModal(
                {
                    modalConfig: {
                        [WALLET_ADAPTERS.TORUS_EVM]: {
                            label: "torus_evm",
                            showOnModal: false,
                        },
                        [WALLET_ADAPTERS.OPENLOGIN]: {
                            label: "openlogin",
                            loginMethods: {
                                facebook: {
                                    name: "facebook",
                                    showOnModal: false,
                                },
                                farcaster: {
                                    name: "farcaster",
                                    showOnModal: false,
                                },
                                reddit: {
                                    name: "reddit",
                                    showOnModal: false,
                                },
                                google: {
                                    name: "google",
                                    showOnModal: false,
                                },
                                discord: {
                                    name: "discord",
                                    showOnModal: false,
                                },
                                twitch: {
                                    name: "twitch",
                                    showOnModal: false,
                                },
                                apple: {
                                    name: "apple",
                                    showOnModal: false,
                                },
                                line: {
                                    name: "line",
                                    showOnModal: false,
                                },
                                github: {
                                    name: "github",
                                    showOnModal: false,
                                },
                                kakao: {
                                    name: "kakao",
                                    showOnModal: false,
                                },
                                linkedin: {
                                    name: "linkedin",
                                    showOnModal: false,
                                },
                                twitter: {
                                    name: "twitter",
                                    showOnModal: false,
                                },
                                weibo: {
                                    name: "weibo",
                                    showOnModal: false,
                                },
                                wechat: {
                                    name: "wechat",
                                    showOnModal: false,
                                },
                                email_passwordless: {
                                    name: "email_passwordless",
                                    showOnModal: false,
                                },
                                // phone: {
                                //     name: "phone",
                                //     showOnModal: false,
                                // },
                                sms_passwordless: {
                                    name: "sms_passwordless",
                                    showOnModal: false,
                                },
                                // sms: {
                                //     name: "sms",
                                //     showOnModal: false,
                                // },
                            },
                        },
                    }
                }
            );

            if (web3auth.provider) {
                setProvider(web3auth.provider);
            }

            await initSession(web3auth);
            return web3auth;
        } catch (error: any) {
            //console.log("Error logging in!", error);
            setIsWalletConnecting(false)

            if (error.message === "User is not whitelisted") {
                setSnackBar({open: true, message: "User is not Whitelisted", severity: "error"});
                // @ts-ignore
                setUser(prevState => ({...prevState, isWhitelisted: false}));
                setIsWalletConnecting(false);
            }
            throw new Error("Error occured:", error.message)

        }
    }

    const login = async (web3Auth: Web3Auth | null) => {
        if (!web3Auth) {
            throw new Error("Web3auth not initialized yet");
        }
        try {
            const web3authProvider = await web3Auth.connect();
            setIsWalletConnecting(true);
            setProvider(web3authProvider);
            await initSession(web3Auth);

        } catch (error: any) {
            //console.log("err", error);
            setIsWalletConnecting(false)

            if (error.message === "User is not whitelisted") {
                setSnackBar({open: true, message: "User is not Whitelisted", severity: "error"});
                // @ts-ignore
                setUser(prevState => ({...prevState, isWhitelisted: false}));
                setIsWalletConnecting(false);
            }
            throw new Error("Error occured:", error.message)
        }
        setIsWalletConnecting(false)
    };

    const logout = async () => {
        if (!web3Auth) {
            throw new Error("Web3auth not initialized yet");
        }
        try {
            setIsWalletConnecting(true);
            await web3Auth.logout();
            localStorage.clear();
            setProvider(null);
            setUser(null);
            setReloadCreditBalance(true);
            navigate("/onboarding");
        } catch {
            setIsWalletConnecting(false);
        } finally {
            setIsWalletConnecting(false);
        }

    };

    return {web3Auth, initWeb3Auth, provider, setProvider, setWeb3Auth, initSession, login, logout}

}
export default useAuth;

