import {PageContentContainer} from "../styled/styled";
import {
    Badge,
    Box, Button,
    CircularProgress,
    InputAdornment,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    TableContainer,
    TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Refresh} from "@mui/icons-material";
import {DataGrid} from "@mui/x-data-grid";
import {SimulationTitleModal} from "../../modals/SimulationTitleModal";
import * as React from "react";
import {FlexBox} from "../simulation/create-simulation/styled";
import SearchIcon from "@mui/icons-material/Search";
import {formatDateValue, formatMonetaryValue} from "../simulation/create-simulation/PoolDetails";
import {SimulationStatus} from "../simulation-history/SimulationStatus";
import {Link, useNavigate} from "react-router-dom";
import {ChainBadge} from "./ChainBadge";
import Chip from "@mui/material/Chip";
import useSWR from "swr";
import {getArtifactLibraryItems, getSmartWallets, getSupportedChains} from "../../api/hasura";
import WalletStatus from "./WalletStatus";
import {LDKeyWallets} from "../../components/layout/Sidebar";
import {useAppState} from "../../state/AppStateProvider";


export const mockWalletsData = [
    {
        id: 1,
        type: "Safe",
        address: "0xb65918dAB9570BFe354855429Cf542C07D177Ca8",
        name: "Degen Wallet",
        status: "In Use",
        usdValue: 4705,
        chain: "Arbitrum"
    },
    {
        id: 2,
        type: "Safe",
        address: "0xb65918dAB9570BFe354855429Cf542C07D177Ca8",
        name: "Safe strats only",
        status: "In Use",
        usdValue: 11250,
        chain: "Ethereum"
    },
    {
        id: 3,
        type: "Safe",
        address: "0xb65918dAB9570BFe354855429Cf542C07D177Ca8",
        name: "Arbitrum 1",
        status: "In Use",
        usdValue: 6900,
        chain: "Optimism"
    },
    {
        id: 4,
        type: "Safe",
        address: "0xb65918dAB9570BFe354855429Cf542C07D177Ca8",
        name: "My First Wallet",
        status: "In Use",
        usdValue: 4200,
        chain: "Ethereum"
    },
    {
        id: 5,
        type: "Safe",
        address: "0xb65918dAB9570BFe354855429Cf542C07D177Ca8",
        name: "Free money here",
        status: null,
        usdValue: 24000,
        chain: "Arbitrum"
    },
];


export type SupportedChain = {
    block_explorer_url: string;
    chain_id: string;
    chain_id_hex: string;
    chain_name_space: string;
    currency: string;
    is_active: boolean;
    is_layer2: boolean;
    is_testnet: boolean;
    minimum_comfirmation_block_number: number;
    name: string;
    rpc_url: string;
}
export const WalletsOverview = () => {

    const {featureFlags} = useAppState();


    const navigate = useNavigate();
    const onChainChange = (e: any) => {
        setSelectedChain(e.target.value)
    };

    const onWalletSelect = (wallet: any) => {
        console.log("Wallet selected wallet", wallet);
        //navigate to wallets/:id

        if (wallet.is_active) {
            navigate(`/wallets/${wallet.id}`);

        } else {
            navigate(`/wallets/create/${wallet.id}`);
        }
    }

    const {
        data: supportedChains,
        isLoading: chainsLoading,
    } = useSWR("supported-chains", getSupportedChains)


    const {
        data: userWallets,
        isLoading: isWalletsLoading,
        mutate: mutateWallets,
        isValidating: isWalletsValidating
    } = useSWR("user-wallets", getSmartWallets)


    const [selectedChain, setSelectedChain] = React.useState<string>("0x1");


    const isWalletsEnabled = featureFlags?.[LDKeyWallets];


    const columns = [
        {field: 'name', headerName: 'Name', flex: 1},
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (item: any) => <WalletStatus wallet={item.row} />
        },
        // {
        //     field: 'usdValue',
        //     headerName: 'Holdings Value',
        //     flex: 1,
        //     renderCell: (item: any) => <span>${formatMonetaryValue(item.row.usdValue)}</span>
        // },
        {
            field: 'chain', headerName: 'Chain', flex: 1, renderCell: (item: any) => {
                const chain = supportedChains?.find(chain => chain.chain_id === item.row.chain_id);

                return chain ? <ChainBadge chain={chain?.name}/> : null
            }
        },
        {field: 'type', headerName: 'Type', flex: 1},

    ];


    if (!isWalletsEnabled) {
        return <PageContentContainer>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <h2>Wallets feature is coming soon!</h2>
            </Box>
        </PageContentContainer>
    }

    return (
        <PageContentContainer>

            <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>

                <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>

                    <h2>
                        Wallets
                    </h2>

                    <Box>
                        <IconButton onClick={mutateWallets} size="small">{isWalletsLoading || isWalletsValidating ? <CircularProgress size={20}/> :
                            <Refresh/>}</IconButton>
                    </Box>

                </Box>

                <FlexBox sx={{justifyContent: "space-between"}}>
                    <FlexBox sx={{gap: 2}}>
                        <Select
                            value={selectedChain}
                            onChange={(e) => onChainChange(e)}
                            size={"small"}>
                            {supportedChains?.map(chain => <MenuItem value={chain.chain_id_hex}
                                                                     key={chain.chain_id}> {chain.name} </MenuItem>)}
                            {/*<MenuItem value={"ETHEREUM"}>Ethereum</MenuItem>*/}
                            {/*<MenuItem disabled value={"POLYGON"}>Polygon</MenuItem>*/}
                            {/*<MenuItem disabled value={"OPTIMISM"}>Optimism</MenuItem>*/}
                        </Select>

                        <Select
                            placeholder={"Select Type"}
                            value={"SAFE"}
                            size={"small"}>
                            <MenuItem value={"SAFE"}>Safe</MenuItem>
                            <MenuItem disabled value={"EULITH"}>Eulith</MenuItem>
                        </Select>


                    </FlexBox>

                    <FlexBox sx={{gap: 2}}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                )
                            }}
                            disabled
                            // onChange={onSearch}
                            size={"small"} variant={"outlined"} placeholder="Search..."
                            sx={{width: "140px"}}/>

                        <Link to={'/wallets/create'}>
                            <Button variant={"contained"}>
                                Create Wallet
                            </Button>
                        </Link>

                    </FlexBox>


                </FlexBox>

            </Box>

            {/*{isLoading && <LinearProgress sx={{width: "100%"}}/>}*/}

            <TableContainer component={Paper} sx={{my: 2}}>

                {userWallets && <DataGrid
                    onRowClick={(item) => onWalletSelect(item.row)}
                    rows={userWallets}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10},
                        },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                />}
            </TableContainer>

            {/*{simulationToRename?.id &&*/}
            {/*    <SimulationTitleModal isOpen={!!simulationToRename?.id} onClose={() => setSimulationToRename(null)}*/}
            {/*                          onSave={onRenameSimulation}*/}
            {/*                          title={simulationToRename?.frontend_state?.title ?? ""}/>}*/}

        </PageContentContainer>
    );
}
